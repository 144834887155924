export const useInboxScanStore = defineStore('inbox-scan', () => {
    const connected = ref(false);
    const top_companies = [
        {
            logo: 'https://logo.clearbit.com/google.com',
            name: 'Google',
            last_received: '2021-09-01',
            emails_count: 100,
        },

        //microsof
        {
            logo: 'https://logo.clearbit.com/microsoft.com',
            name: 'Microsoft',
            last_received: '2021-09-01',
            emails_count: 90,
        },

        //apple
        {
            logo: 'https://logo.clearbit.com/apple.com',
            name: 'Apple',
            last_received: '2021-09-01',
            emails_count: 80,
        },
    ];
    const email_stats = {
        email: 'example@domain.com',
        last_scan: '2021-09-01',
        next_scan: '2021-09-08',
        emails_processed: 1000,
        emails_ignored: 100,
        total_company_emails: 100,
        known_companies: 10,
        emails_from_known_companies: 500,
        unknown_companies: 5,
        emails_from_unknown_companies: 500,
    };

    const recent_emails = [
        {
            company_name: 'Google',
            last_received: '2021-09-01',
            emails_count: 2,
            logo: 'https://logo.clearbit.com/google.com',
        },

        //spotify
        {
            company_name: 'Spotify',
            last_received: '2021-09-01',
            emails_count: 5,
            logo: 'https://logo.clearbit.com/spotify.com',
        },

        //slack
        {
            company_name: 'Slack',
            last_received: '2021-09-01',
            emails_count: 6,
            logo: 'https://logo.clearbit.com/slack.com',
        },

        //amazon
        {
            company_name: 'Amazon',
            last_received: '2021-09-01',
            emails_count: 7,
            logo: 'https://logo.clearbit.com/amazon.com',
        },
    ];

    const connect = async () => {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        connected.value = true;
    };

    const disconnect = async () => {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        connected.value = false;
    };

    return {
        connected,
        connect,
        disconnect,
        top_companies,
        recent_emails,
        email_stats,
    };
});
