<template>
    <div class="bg-white-700 rounded-md px-9 py-6 flex justify-center flex-col gap-2">
        <span class="modal-title">Disconnect Your IMAP Connection</span>

        <span class="modal-paragraph max-w-[300px]">
            This will disconnect Privacy Bee from having access to your gmail. All data we have will be immediately removed.
        </span>

        <div class="flex justify-between mt-6">
            <prime-button label="Cancel" variant="text" class="text-blue-700 font-semibold" @click="closeModal()" />
            <prime-button label="Disconnect" @click="disconnectEmail" :loading="isDisconnecting" />
        </div>
    </div>
</template>

<script setup>
import { useInboxScanStore } from '~/stores/personal/inbox-scan';

const inbox_scan_store = useInboxScanStore();

import { inject } from 'vue';

const dialogRef = inject('dialogRef');

const isDisconnecting = ref(false);

async function disconnectEmail() {
    isDisconnecting.value = true;
    await inbox_scan_store.disconnect();
    isDisconnecting.value = false;

    dialogRef.value.close();
}

function closeModal() {
    dialogRef.value.close();
}
</script>

<style lang="scss" scoped>
.modal-title {
    color: var(--slate-600, rgba(2, 6, 23, 0.75));
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.modal-paragraph {
    color: var(--slate-600, rgba(2, 6, 23, 0.75));
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
</style>
