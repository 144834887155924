<template>
  <div class="bg-white-700 rounded-md p-6 flex justify-center flex-col gap-2 max-w-[480px] mx-6 lg:mx-0">
    <div class="flex items-center justify-between mb-4">
      <div class="flex flex-col">
        <span class="modal-title">Gift Subscription</span>
        <span class="text-sm text-slate-600">Gift a subscription to {{ currentContact?.name }}</span>
      </div>

      <svg @click="closeModal" xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="fill-slate-900 cursor-pointer" viewBox="0 0 256 256">
        <path d="M204.24,195.76a6,6,0,1,1-8.48,8.48L128,136.49,60.24,204.24a6,6,0,0,1-8.48-8.48L119.51,128,51.76,60.24a6,6,0,0,1,8.48-8.48L128,119.51l67.76-67.75a6,6,0,0,1,8.48,8.48L136.49,128Z"></path>
      </svg>
    </div>

    <div v-if="!isGiftSuccess">
      <div class="w-full flex border border-slate-200 rounded-sm p-4">
        <div class="flex items-center gap-4 justify-center w-full">
          <div class="user-avatar" :style="`background-image: url('${currentContact?.profilePicture}')`"></div>
          <div class="flex flex-col flex-1">
            <p class="text-sm font-semibold">{{ currentContact?.email }}</p>
            <p class="text-xs text-slate-500">Renews on {{ currentContact?.renewalDate }}</p>
          </div>
          <div class="flex flex-1"></div>
          <prime-button label="Gift" @click="handleGift" :loading="isGiftLoading" />
        </div>
      </div>

      <p class="text-xs text-slate-600 mt-2">
        Your payment method ending in {{ paymentLast4 }} will be charged {{ formattedAmount }} USD. You can cancel this for a pro-rated refund at any time.
      </p>
    </div>

    <div v-else class="flex flex-col items-center">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
      </svg>
      <p class="text-sm font-semibold mt-4">Subscription successfully gifted to {{ currentContact?.name }}!</p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, inject } from 'vue';
import { useGiftingModalStore } from '~/stores/personal/useGiftingModalStore';

const modalStore = useGiftingModalStore();

const dialogRef = inject('dialogRef');

const isGiftLoading = ref(false);
const isGiftSuccess = ref(false);

const currentContact = computed(() => modalStore.currentContact);

const paymentLast4 = computed(() => currentContact.value?.paymentLast4 || 'XXXX');

const formattedAmount = computed(() => {
  if (currentContact.value) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(currentContact.value.amount);
  }
  return '$0.00';
});

function closeModal() {
  modalStore.closeGiftingModal();
	dialogRef.value.close();
}

function handleGift() {
  isGiftLoading.value = true;

  setTimeout(() => {
    isGiftLoading.value = false;
    isGiftSuccess.value = true;
  }, 2000);
}

onMounted(() => {
  isGiftSuccess.value = false;
});
</script>

<style lang="scss" scoped>
.modal-title {
  color: var(--slate-600, rgba(2, 6, 23, 0.75));
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.modal-paragraph {
  color: var(--slate-600, rgba(2, 6, 23, 0.75));
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.user-avatar {
  @apply w-10 h-10 bg-slate-300 rounded-full;
  background-size: cover;
  background-position: center;
}

.flex-col.items-center {
  text-align: center;
}

.svg-success {
  @apply h-12 w-12 text-green-500;
}
</style>
