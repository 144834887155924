import { defineStore } from 'pinia';

interface ContactInfo {
  name: string;
  email: string;
  profilePicture: string;
  renewalDate: string;
  paymentLast4: string;
  amount: number;
}

export const useGiftingModalStore = defineStore('giftingModalStore', {
  state: () => ({
    isGiftingModalOpen: false,
    currentContact: null as ContactInfo | null,
  }),
  actions: {
    openGiftingModal(contact: ContactInfo) {
      this.currentContact = contact;
      this.isGiftingModalOpen = true;
    },
    closeGiftingModal() {
      this.currentContact = null;
      this.isGiftingModalOpen = false;
    },
  },
});
